import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 887.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,887.000000) scale(0.100000,-0.100000)">


<path d="M3535 6179 c-32 -16 -51 -19 -91 -14 -66 8 -196 -26 -243 -63 -18
-15 -54 -39 -79 -53 -66 -38 -72 -51 -72 -144 0 -74 -3 -85 -27 -117 -16 -20
-40 -43 -55 -52 -57 -35 -67 -61 -77 -220 -6 -80 -12 -150 -15 -155 -3 -4 -12
-42 -21 -84 -15 -74 -15 -79 4 -121 10 -24 31 -52 45 -61 21 -14 26 -25 26
-57 0 -23 -8 -51 -19 -66 -21 -30 -41 -94 -41 -132 0 -15 -14 -38 -35 -59 -19
-19 -35 -40 -35 -48 0 -8 -16 -35 -35 -60 -19 -26 -38 -64 -41 -85 -6 -37 -8
-38 -48 -38 l-42 0 -23 -92 c-12 -51 -24 -124 -27 -163 -6 -85 -7 -104 -5
-255 1 -30 2 -167 4 -305 2 -137 7 -262 11 -278 5 -17 17 -29 34 -34 14 -3
127 -1 251 4 222 11 318 6 363 -18 53 -29 168 -162 168 -196 0 -6 14 -29 30
-51 17 -23 30 -48 30 -56 0 -19 56 -153 85 -202 32 -54 125 -139 189 -171 47
-24 57 -25 141 -19 125 8 345 37 470 60 44 9 109 16 145 16 95 0 137 9 143 30
4 17 105 90 125 90 5 0 13 18 17 41 4 23 20 63 37 89 16 26 32 67 35 91 2 24
11 61 19 82 8 21 11 49 8 64 -6 21 1 32 42 69 33 30 57 44 76 44 19 0 28 5 28
16 0 15 -12 16 -93 12 -78 -4 -99 -9 -125 -28 -38 -28 -56 -80 -40 -116 8 -17
8 -27 -1 -38 -6 -7 -15 -38 -18 -67 -5 -40 -15 -66 -40 -98 -18 -24 -33 -48
-33 -53 0 -5 -6 -16 -14 -24 -8 -7 -16 -19 -18 -25 -3 -8 -13 -7 -36 5 l-32
17 0 -34 c0 -48 -15 -57 -93 -57 -61 0 -110 -8 -252 -40 -32 -7 -42 -6 -52 7
-11 14 -14 14 -37 0 -24 -16 -223 -47 -302 -47 -63 0 -153 66 -200 146 -33 56
-76 183 -71 209 3 16 -4 25 -25 34 -20 9 -28 20 -28 37 0 13 -3 24 -7 24 -5 0
-21 23 -38 52 -35 59 -61 89 -127 141 -88 70 -97 72 -348 71 -124 0 -245 -3
-268 -7 l-44 -8 6 58 c6 64 6 58 6 196 0 54 5 107 10 117 7 13 6 21 -4 29 -18
15 -27 130 -15 177 8 28 8 48 -2 76 -9 30 -10 41 -1 50 7 7 12 45 13 87 1 113
7 125 57 132 23 3 46 10 51 15 16 18 30 75 25 102 -4 21 2 35 30 65 19 21 36
48 38 59 2 15 13 24 34 30 25 6 35 18 52 57 18 44 19 51 6 62 -27 22 4 96 34
84 9 -3 19 0 24 7 12 19 10 72 -4 86 -6 6 -12 36 -13 67 -2 59 -13 78 -57 101
-28 14 -29 17 -22 62 8 59 18 84 39 105 11 12 14 24 9 39 -5 13 -7 28 -5 33 2
6 4 57 5 114 1 99 2 104 26 120 24 15 71 60 98 95 23 29 32 67 32 137 0 66 1
70 27 80 14 5 41 23 59 39 47 41 103 54 216 51 85 -2 98 0 110 17 16 23 65 37
82 23 26 -21 183 -71 224 -71 40 0 49 -5 101 -58 32 -32 66 -73 76 -92 40 -79
92 -102 131 -59 14 15 26 19 48 15 23 -5 44 3 102 39 71 45 73 45 121 35 61
-14 104 -34 128 -60 10 -11 26 -20 36 -20 10 0 31 -11 47 -25 47 -40 107 -55
237 -62 l121 -6 59 63 c69 71 100 90 149 90 19 0 59 9 88 21 48 19 68 20 198
16 151 -5 233 -23 304 -66 44 -27 141 -38 161 -18 8 9 15 23 15 31 0 9 15 22
33 30 19 7 42 25 53 38 19 25 71 48 107 48 11 0 31 12 44 27 19 19 37 27 71
30 26 2 52 0 57 -4 18 -14 138 -187 151 -218 19 -47 44 -178 38 -203 -7 -24
-2 -27 57 -36 22 -4 50 -20 71 -40 34 -33 35 -35 26 -87 -12 -68 -13 -108 -3
-136 6 -19 -1 -27 -51 -60 -32 -21 -63 -41 -69 -45 -11 -8 13 -29 63 -53 31
-15 33 -15 75 14 64 43 73 57 52 77 -21 21 -16 46 13 60 23 11 24 18 28 135
l5 124 -55 47 c-30 27 -66 52 -79 57 -21 8 -28 22 -42 83 -23 100 -41 137
-129 259 -87 122 -106 134 -209 133 -59 0 -73 -5 -111 -31 -24 -17 -69 -36
-100 -43 -46 -9 -58 -17 -76 -46 -12 -19 -28 -34 -35 -34 -7 0 -28 -12 -45
-27 -30 -25 -34 -26 -64 -13 -137 58 -200 71 -351 77 -135 5 -156 3 -212 -16
-34 -11 -79 -21 -99 -21 -51 0 -145 -53 -189 -106 l-37 -44 -72 0 c-88 1 -155
15 -181 40 -11 11 -36 26 -55 35 -19 9 -57 29 -83 45 -53 33 -115 50 -184 52
-33 0 -57 -8 -101 -34 -39 -25 -74 -37 -114 -42 -54 -6 -57 -5 -75 23 -32 51
-103 121 -145 142 -23 12 -67 25 -98 29 -31 4 -92 22 -134 39 -92 37 -154 40
-211 10z"/>
<path d="M6995 5318 c-95 -15 -181 -31 -192 -34 -28 -8 -10 -23 30 -26 l42 -3
0 -435 0 -435 -39 2 c-22 2 -42 5 -45 9 -3 3 -7 159 -9 346 l-3 340 -34 -6
c-32 -6 -190 -26 -207 -26 -5 0 -8 -7 -8 -15 0 -11 11 -15 39 -15 26 0 41 -5
45 -16 3 -9 6 -115 6 -237 0 -213 -1 -224 -24 -273 -37 -80 -91 -121 -141
-108 -41 10 -45 46 -45 384 l0 317 -53 -12 c-29 -7 -88 -16 -131 -20 -84 -7
-100 -26 -30 -35 l39 -5 5 -275 c5 -258 7 -277 27 -316 36 -67 75 -94 137 -94
62 0 143 36 180 81 14 16 27 29 30 29 3 0 6 -24 8 -52 2 -40 7 -52 18 -51 116
18 185 20 216 7 63 -26 134 -11 134 29 0 7 4 18 9 26 7 11 14 9 37 -13 71 -65
198 -74 272 -19 115 84 169 216 160 392 -13 245 -163 385 -331 308 -30 -14
-60 -35 -66 -46 -24 -44 -31 -17 -33 142 l-3 162 -40 -7z m235 -305 c20 -19
35 -46 45 -81 21 -81 28 -235 16 -351 -17 -164 -56 -226 -137 -219 -32 2 -43
10 -66 43 -39 55 -51 143 -46 334 5 165 15 207 68 274 29 37 79 37 120 0z"/>
<path d="M5732 5283 c-51 -22 -113 -80 -138 -131 -27 -55 -31 -169 -8 -233 31
-86 76 -117 245 -167 201 -60 231 -85 237 -202 4 -60 1 -75 -21 -112 -69 -118
-247 -125 -345 -13 -46 52 -79 123 -93 199 -8 43 -17 66 -26 66 -19 0 -14
-351 5 -358 6 -2 12 3 12 11 0 38 26 39 131 6 89 -28 112 -31 179 -26 120 8
199 59 249 159 27 53 31 72 31 144 0 99 -20 154 -76 207 -32 30 -63 45 -169
78 -203 65 -249 95 -260 173 -14 89 38 174 115 191 120 26 235 -65 290 -231
35 -103 46 -80 41 87 -2 79 -6 148 -9 153 -3 5 -15 -3 -28 -19 l-22 -28 -68
28 c-90 36 -209 43 -272 18z"/>
<path d="M4500 5109 c-86 -15 -155 -18 -380 -18 l-274 1 -3 -39 c-2 -21 -8
-68 -13 -105 -12 -82 -12 -88 -1 -88 4 0 14 20 21 45 24 85 117 165 191 165
l29 0 -2 -352 -3 -353 -47 -3 c-27 -2 -48 -7 -48 -13 0 -5 72 -9 175 -9 103 0
175 4 175 9 0 6 -21 11 -47 13 l-48 3 -3 353 -2 352 32 0 c79 0 166 -77 191
-167 11 -43 27 -58 27 -26 0 9 -5 56 -11 105 -7 49 -9 91 -6 94 3 3 15 3 26
-1 21 -6 21 -9 19 -358 l-3 -352 -30 -3 c-16 -2 -34 -8 -39 -13 -6 -5 45 -9
132 -9 82 0 142 4 142 9 0 6 -15 11 -32 13 l-33 3 -3 202 c-3 226 3 252 68
307 38 32 64 33 84 4 14 -19 16 -62 16 -270 l0 -248 -35 0 c-19 0 -35 -4 -35
-10 0 -6 53 -10 140 -10 81 0 140 4 140 9 0 6 -17 11 -37 13 l-38 3 -5 245
c-4 215 -7 248 -23 272 -54 79 -146 76 -246 -7 l-31 -27 0 141 c0 107 -3 141
-12 140 -7 -1 -60 -10 -118 -20z"/>
<path d="M5114 4921 c-46 -21 -94 -77 -125 -147 -18 -39 -22 -69 -23 -145 -1
-91 1 -100 34 -165 28 -54 46 -76 85 -102 47 -32 55 -34 117 -30 63 3 71 6
109 43 45 44 73 92 82 143 8 42 -12 39 -26 -4 -40 -120 -150 -171 -217 -99
-26 28 -50 118 -50 192 0 64 -1 64 165 61 l120 -3 -1 55 c-1 58 -24 114 -66
161 -52 59 -129 74 -204 40z m127 -46 c15 -34 19 -62 17 -117 l-3 -73 -54 -3
c-87 -5 -101 2 -101 48 0 109 39 190 91 190 26 0 33 -6 50 -45z"/>
<path d="M3970 4251 c0 -6 19 -11 43 -13 l42 -3 3 -350 c3 -386 4 -375 -60
-375 -15 0 -28 -4 -28 -10 0 -7 141 -10 404 -10 259 0 407 4 411 10 3 6 0 10
-8 10 -8 0 -25 8 -38 19 l-23 19 31 68 c17 38 34 79 38 92 3 12 10 22 15 22
10 0 80 -166 80 -191 0 -12 -9 -21 -25 -25 -56 -14 -6 -24 121 -24 l134 0 0
-115 0 -115 -36 0 c-24 0 -34 -4 -31 -12 3 -10 39 -13 137 -13 98 0 134 3 138
13 2 8 -8 12 -32 12 l-36 0 0 129 0 130 46 -21 c105 -46 217 12 272 143 l18
41 34 -68 c53 -103 134 -149 233 -130 69 13 146 103 162 189 10 50 -9 48 -24
-3 -7 -23 -31 -61 -55 -85 -36 -38 -49 -45 -80 -45 -89 0 -139 80 -134 213 l3
69 135 -1 c74 -1 141 3 149 8 12 7 12 19 3 63 -15 76 -26 101 -64 144 -101
115 -247 87 -325 -64 l-32 -61 -12 37 c-13 41 -51 97 -83 124 -59 50 -151 42
-211 -18 l-35 -35 0 42 0 42 -42 -7 c-24 -4 -105 -7 -180 -6 -128 0 -173 -9
-114 -24 37 -9 37 -28 1 -112 -27 -61 -36 -74 -41 -59 -4 11 -18 46 -30 77
-29 70 -30 88 -4 95 53 14 7 23 -120 23 -132 0 -178 -10 -111 -25 39 -8 41
-11 117 -166 l66 -133 -20 -35 c-11 -20 -34 -63 -52 -96 -18 -33 -37 -66 -43
-73 -17 -21 -85 -53 -92 -42 -5 9 11 178 21 213 4 11 0 17 -10 17 -10 0 -16
-9 -16 -22 0 -38 -45 -122 -80 -151 -18 -15 -49 -35 -69 -43 -45 -19 -181 -29
-201 -15 -12 8 -16 43 -18 186 l-3 175 51 0 c45 0 56 -4 85 -33 24 -24 35 -46
40 -80 14 -95 25 -43 25 123 0 164 -11 218 -24 126 -11 -70 -55 -106 -138
-109 l-33 -2 0 165 0 165 68 3 c129 6 216 -46 253 -148 35 -98 44 -50 16 85
l-17 80 -282 3 c-176 1 -283 -1 -283 -7z m1867 -176 c29 -20 43 -73 43 -157
l0 -68 -80 0 -80 0 0 53 c0 70 25 152 53 172 27 19 37 19 64 0z m-421 -53 c31
-46 44 -116 44 -235 0 -173 -35 -269 -98 -275 -44 -4 -71 13 -93 61 -16 35
-19 67 -19 200 0 165 6 195 51 258 18 25 27 30 57 27 28 -2 41 -10 58 -36z
m-308 -239 c2 -184 -1 -263 -8 -263 -31 0 -55 33 -126 174 l-78 155 29 62 c17
34 43 80 58 101 26 37 30 39 75 36 l47 -3 3 -262z"/>
<path d="M6502 4219 c-12 -25 -42 -64 -65 -87 -39 -39 -45 -41 -75 -33 -46 14
-95 -3 -126 -44 -14 -18 -26 -38 -26 -44 0 -6 -4 -11 -10 -11 -5 0 -10 23 -10
50 0 28 -4 50 -9 50 -19 0 -166 -21 -183 -26 -30 -9 -20 -23 20 -26 l37 -3 0
-265 0 -265 -38 -3 c-20 -2 -35 -7 -32 -13 4 -5 69 -9 145 -9 76 0 141 4 145
9 3 6 -12 11 -32 13 l-38 3 0 220 c0 208 1 222 22 261 12 23 28 44 36 47 22 8
40 -8 33 -30 -9 -28 17 -46 58 -41 l34 4 4 -213 3 -213 33 -32 c30 -31 37 -33
101 -33 62 0 73 3 108 31 21 17 40 40 42 50 2 15 -3 14 -28 -10 -35 -34 -87
-38 -108 -10 -10 13 -13 79 -13 261 l0 243 54 0 c33 0 58 5 65 13 17 21 3 27
-60 27 l-58 0 -3 88 -3 87 -23 -46z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
